import {withAuthenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';

import {
  HashRouter, 
  Route, 
  Routes
} from "react-router-dom";
import Main from "./screen/Main/Main";

import { AwsRum } from 'aws-rum-web';

try {
  const config = {
    sessionSampleRate: 1,
    guestRoleArn: "arn:aws:iam::567455040737:role/RUM-Monitor-us-west-2-567455040737-6619003546861-Unauth",
    identityPoolId: "us-west-2:206cef40-f668-4162-b991-21e92a7500f2",
    endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
    telemetries: ["performance","errors","http"],
    allowCookies: true,
    enableXRay: false
  };

  const APPLICATION_ID = '2ec90736-5612-4349-84c0-9e31a0ccc200';
  const APPLICATION_VERSION = '1.0.0';
  const APPLICATION_REGION = 'us-west-2';

  const awsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

Amplify.configure(awsconfig);

function App() {
  return (
      <HashRouter>
        <Routes>
          <Route exact path="/" element={<Main level="private"/>}/>
          <Route exact path="/public" element={<Main level="public"/>}/>
        </Routes>
      </HashRouter>
  );
}

export default withAuthenticator(App);
// export default App;
